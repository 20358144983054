import Parse from "parse";

Parse.initialize("1234567890");
Parse.serverURL = "https://users.kompetenzzentrum-siegen.digital/parse";

// Parse.initialize("01234567890");
// Parse.serverURL = "https://db.henrichgruppe.com/user/parse";

Parse.LiveQuery.on("error", (error) => {
  // console.error("Parse WS Connection failed:");
  // console.error(error);

  Parse.LiveQuery.close();
});

export const ParseAdapterConfig = {
  sourcesClass: "OD3Sources",
  userDataClass: "OD3UserData",
  navigationGroupClass: "OD3NavigationGroup",
  navigationItemClass: "OD3NavigationItem",
  dashboardsClass: "OD3Dashboards",
  widgetsClass: "OD3Widgets",
  alarmClass: "OD3Alarm",
  alarmWebhookClass: "OD3AlarmWebhook",
};

export const ParseAdminConfig = [
  {
    key: "roles",
    className: "_Role",
    relation: ["name"],
    columns: ["name", "users", "createdAt", "updatedAt"],
    create_fields: ["name"],
    edit_fields: ["name"],
  },
  {
    key: "users",
    className: "_User",
    relation: ["username"],
    columns: ["name", "username", "email", "rsAuthorityVerified", "updatedAt"],
    create_fields: ["name", "username", "email", "password"],
    edit_fields: ["name", "username", "email", "password"],
  },
  {
    key: "sources",
    className: "OD3Sources",
    relation: ["name", "tag"],
    columns: ["name", "tag", "meta"],
    create_fields: ["name", "tag", "meta"],
    edit_fields: ["name", "tag", "meta"],
  },
  {
    key: "s7config",
    className: "OWS7DB",
    relation: ["label"],
    columns: [
      "label",
      "address",
      "S7Datatype",
      "unit",
      "enabled",
      "ReadInterval",
      "note",
      "DBID",
      "source",
    ],
    create_fields: [
      "label",
      "address",
      "S7Datatype",
      "unit",
      "enabled",
      "ReadInterval",
      "note",
      "DBID",
      "source",
    ],
    edit_fields: [
      "label",
      "address",
      "S7Datatype",
      "unit",
      "enabled",
      "ReadInterval",
      "note",
      "DBID",
      "source",
    ],
  },
  {
    key: "nav-groups",
    className: "OD3NavigationGroup",
    relation: ["label"],
    columns: ["label", "icon", "order"],
    create_fields: ["label", "icon", "order"],
    edit_fields: ["label", "icon", "order"],
  },
  {
    key: "nav-items",
    className: "OD3NavigationItem",
    relation: ["label"],
    columns: [
      "group",
      "label",
      "place",
      "order",
      "routeCondition",
      "link",
      "activeCondition",
    ],
    create_fields: [
      "group",
      "label",
      "place",
      "order",
      "routeCondition",
      "link",
      "activeCondition",
    ],
    edit_fields: [
      "group",
      "label",
      "place",
      "order",
      "routeCondition",
      "link",
      "activeCondition",
    ],
  },
];
